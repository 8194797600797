<template>
  <div v-if="total > 0">
    <chart
      :series="series" type="line" :loading="loading"
      :error="error"
      style="height: 200px; margin-bottom: 2rem;"
    />
    <div class="columns">
      <div class="column is-3">
        <search-mappings
          :dates="dates" :mappings="mappings" :index="index"
          @query="updateQuery"
        />
      </div>
      <div class="column">
        <data-table
          ref="table" :items="items"
          :disable-filtering="true"
          :pagination="['top', 'bottom']"
          :theme="$_ui_theme_tables" @loaded="tableLoaded"
        >
          <data-column
            field="_source.#timestamp" label="Key" width="20%"
            :sortable="false"
          >
            <template slot-scope="{value}">
              {{ format(new Date(value), 'dd/MM/yyyy HH:mm:ss') }}
            </template>
          </data-column>
          <data-column field="_source.#raw" label="Value" :sortable="false">
            <template slot-scope="{value}">
              <snippet v-if="isJSON(value)" :code="value" language="json" />
              <p v-else>
                {{ value }}
              </p>
            </template>
          </data-column>
        </data-table>
      </div>
    </div>
  </div>
  <p v-else class="has-text-centered">
    {{ $t('search.errorNoEvents') }}
  </p>
</template>
<script>
import Chart from '@/components/Chart'
import SearchMappings from '@/components/SearchMappings'
import { DataTable, DataColumn, Snippet } from '@cyradar/ui'
import { makeLineData, isJSON } from '@/utils'
import format from 'date-fns/format'

export default {
  components: { DataTable, DataColumn, Chart, Snippet, SearchMappings },
  props: {
    total: {
      type: Number,
      required: true
    },
    dates: {
      type: Array,
      required: true
    },
    searchData: {
      type: Object,
      required: true
    },
    mappings: {
      type: Object,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    indexQuery: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      result: null,
      stale: false,
      loading: false,
      error: ''
    }
  },
  computed: {
    overviewQuery () {
      return `${this.indexQuery} | timechart by _index`
    },
    events () {
      if (!this.result) {
        return []
      }

      return this.result.events
    },
    series () {
      if (!this.result) {
        return {
          columns: [],
          duration: 0
        }
      }

      const x = this.events.map(event => {
        return {
          name: event.key,
          data: event.data.map(hit => [hit.key, hit.doc_count])
        }
      })

      return makeLineData(x, this.result.interval, this.result.start_time, this.result.end_time)
    }
  },
  watch: {
    searchData: {
      immediate: true,
      handler  (val) {
        this.loadChart()
        if (!this.$refs.table) {
          return
        }

        this.stale = false
        this.$refs.table.reloadItems()
      }
    }
  },
  methods: {
    isJSON,
    format,
    updateQuery (val) {
      this.$emit('query', val)
    },
    tableLoaded () {
      this.stale = true
    },
    items (filtering, sorting, { page }) {
      const total = this.total > 10000 ? 10000 : this.total
      const payload = {
        query: this.indexQuery,
        start: this.dates[0],
        end: this.dates[1],
        from: 10 * (page - 1)
      }

      if (!this.stale && !this.searchData.types && this.searchData.events instanceof Array) {
        return {
          total,
          items: this.searchData.events
        }
      }

      return this.$_search({ ...payload, pure: true }).then(data => {
        if (!data || !data.data) {
          return
        }

        return data.data
      }).then(events => {
        if (!events) {
          return {
            total: 0,
            items: []
          }
        }

        return {
          total,
          items: events
        }
      })
    },
    loadChart () {
      this.error = ''
      this.loading = true
      const payload = {
        query: this.overviewQuery,
        start: this.dates[0],
        end: this.dates[1]
      }

      this.$_search(payload)
        .then(normalized => {
          this.result = normalized
        })
        .catch(e => {
          this.error = e.message
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss">
  [data-elm="cell"] {
    code[class*="language-"],
    pre[class*="language-"] {
      word-break: break-word!important;
      white-space: pre-wrap!important;
    }
  }
</style>
