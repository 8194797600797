<template>
  <div>
    <dropdown>
      <a slot="v" href="#">
        <octicon :icon="pin" /> Save As
      </a>

      <a
        v-if="vizs && vizs.length" class="dropdown-item" href="#"
        @click.prevent="saveAsWidget(false)"
      >
        Dashboard Widget
      </a>

      <a
        v-if="vizs && vizs.length" class="dropdown-item" href="#"
        @click.prevent="saveAsWidget(true)"
      >
        Report Widget
      </a>

      <a class="dropdown-item" href="#" @click.prevent="$router.push({ name: 'alerts', params: { query } })">
        Alert
      </a>
    </dropdown>
    <modal v-if="widget" :show.sync="modal">
      <box>
        <div slot="header">
          Saving The Query
        </div>
        <form @submit.prevent="storeAsWidget">
          <div class="field">
            <label class="label">Title</label>
            <div class="control">
              <input
                v-model="widget.title" class="input"
                type="text" placeholder="Title"
                required
              >
            </div>
          </div>

          <template v-if="!widget.isReport">
            <label class="label">Dashboard</label>
            <div class="field">
              <p class="control">
                <span class="select is-fullwidth">
                  <select v-model="widget.dashboard" required>
                    <option disabled value="">Select a dashboard below</option>
                    <option v-for="x in dashboards" :key="x.id" :value="x.id">{{ x.title }}</option>
                  </select>
                </span>
              </p>
            </div>
          </template>
          <template v-else>
            <label class="label">Report</label>
            <div class="field">
              <p class="control">
                <span class="select is-fullwidth">
                  <select v-model="widget.report" required>
                    <option disabled value="">Select a report below</option>
                    <option v-for="x in reports" :key="x.id" :value="x.id">{{ x.title }}</option>
                  </select>
                </span>
              </p>
            </div>
          </template>

          <label class="label">Visualization</label>
          <div class="field">
            <p class="control">
              <span class="select is-fullwidth">
                <select v-model="widget.viz" required>
                  <option disabled value="">Select a chart type below</option>
                  <option
                    v-for="x in vizs" :key="x.value" :value="x.value"
                    :disabled="x.disabled"
                  >{{ x.name }}</option>
                </select>
              </span>
            </p>
          </div>

          <div class="columns field-colors">
            <div class="column is-narrow">
              <label class="label">Colors</label>
              <popover placement="bottom-start" fixed>
                <button
                  slot="trigger" class="button" type="button"
                >
                  <octicon :icon="paintcan" />
                  <span>Colors</span>
                </button>
                <div class="buttons">
                  <button
                    v-for="color in colors" :key="color.name" type="button"
                    :class="['button', color.value ? `is-color-${color.value}` : '']" @click.prevent="selectColor(color)"
                  />
                </div>
              </popover>
            </div>
            <div class="column">
              <label class="label">Preview</label>
              <div class="preview-area">
                <box slot="trigger" :class="['box-widget', widgetColor.value ? `is-color-${widgetColor.value}` : '']">
                  <div slot="header">
                    Title
                  </div>
                  Content
                </box>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <input
                v-model="widget.description" class="input"
                type="text" placeholder="Description"
              >
            </div>
          </div>

          <div class="field">
            <label class="label">Query</label>
            <div class="control">
              <textarea
                v-model="widget.query" class="textarea" placeholder="index:something"
                required
              />
            </div>
          </div>

          <div class="field has-addons">
            <div class="control">
              <span class="button is-static"><octicon :icon="calendar" /></span>
            </div>
            <div class="control is-expanded">
              <input
                class="input" type="text" :value="rangeText"
                readonly
              >
            </div>
          </div>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                Submit
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="modal = false">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
  </div>
</template>
<script>
import { pin, calendar, paintcan } from 'octicons-vue'
import { Modal } from '@cyradar/ui'
import { colors } from '@/utils'

export default {
  components: { Modal },
  props: {
    rangeText: {
      type: String,
      default: ''
    },
    range: {
      type: String,
      required: true
    },
    dates: {
      type: Array,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    type: {
      type: Object,
      default: null
    },
    vizs: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dashboards: [],
      reports: [],
      widget: null,
      modal: false
    }
  },
  computed: {
    pin () {
      return pin
    },
    calendar () {
      return calendar
    },
    paintcan () {
      return paintcan
    },
    colors () {
      return colors
    },
    widgetColor () {
      if (!this.widget || !this.widget.color) {
        return {}
      }

      const c = this.colors.find(c => c.value === this.widget.color)
      if (!c) {
        return {}
      }

      return c
    }
  },
  methods: {
    selectColor (color) {
      this.$set(this.widget, 'color', color.value)
    },
    saveAsWidget (report) {
      this.resetWidget(report)

      if (!report) {
        this.loadDashboards().then(() => {
          this.modal = true
        })
        return
      }

      this.loadReports().then(() => {
        this.modal = true
      })
    },
    storeAsWidget () {
      return this.$http.post('/api/v1/widgets', this.widget).then(body => {
        this.modal = false
        this.resetWidget()

        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })
      })
    },
    resetWidget (report) {
      const viz = this.vizs.find(x => !x.disabled)
      const widget = {
        title: '',
        description: '',
        query: this.query,
        viz: this.type && this.type.value ? this.type.value : (viz ? viz.value : ''),
        range: this.range || '',
        dates: this.dates,
        isReport: report
      }

      if (!report) {
        widget.dashboard = this.dashboards && this.dashboards.length === 1 ? this.dashboards[0].id : ''
        this.widget = widget
        return
      }

      widget.report = this.reports && this.reports.length === 1 ? this.reports[0].id : ''
      this.widget = widget
    },
    loadDashboards () {
      return this.$http.get('/api/v1/dashboards').then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }

        this.dashboards = body.data.data
      })
    },
    loadReports () {
      return this.$http.get('/api/v1/reports').then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }

        this.reports = body.data.data.items
      })
    }
  }
}
</script>
<style lang="scss">
.field-colors {
  .popover__ref {
    min-width: 100%;
  }

  .preview-area {
    background-color: #fff;
    padding: 1em;

    .box {
      margin-bottom: 0;
    }

    .dashboard.is-dark & {
      background-color: #1f2128;
    }
  }
}
</style>
