<template>
  <div class="autocomplete">
    <input
      ref="input" type="text" class="input"
      :value="input"
      placeholder="Enter the query (e.g. index:something #host:something AND ip:10.0.0.10 | top by client)"
      @input="onChange"
      @keyup.down="onArrowDown"
      @keyup.up="onArrowUp"
      @keyup.enter="onEnter"
    >
    <ul
      v-show="isOpen"
      class="autocomplete-results"
    >
      <li
        v-for="(result, i) in results"
        :key="i"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
        @click.prevent="setResult(result)"
      >
        {{ result }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'SearchAutocomplete',
  props: {
    mappings: {
      type: Object,
      default: () => {}
    },
    indexes: {
      type: Array,
      default: () => []
    },
    input: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: false,
      results: [],
      commands: ['top by', 'timechart by', 'table by', 'count by', 'dns', 'location', 'malicious'],
      conditions: ['AND', 'OR', 'NOT'],
      items: this.indexes,
      arrowCounter: -1,
      currentIndex: ''
    }
  },
  computed: {
    lastWord () {
      var last = this.input.split(' ').pop()
      return last.split(',').pop()
    }
  },
  watch: {
    lastWord (val) {
      this.filterResults()
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    setItemsByIndex (input) {
      var matches = input.match(/^index:(\S+)\s/m)
      if (!matches || matches.length < 2) {
        this.currentIndex = ''
        this.items = this.indexes
        return
      }

      this.currentIndex = matches[1]
      this.items = this.mappings[this.currentIndex]
      this.items = this.items.concat(this.conditions)
    },
    setItems (input) {
      const commandMatches = input.match(/^.*[|](.*)/m)
      if (!commandMatches) {
        this.setItemsByIndex(input)
        return
      }

      const cmd = commandMatches[1].replace(/ /g, '')
      if (cmd.length === 0) {
        this.items = this.commands
        return
      }

      const lastChar = commandMatches[1].substr(commandMatches[1].length - 1)
      if (lastChar === ' ') {
        this.setItemsByIndex(input)
      }
    },
    onChange (event) {
      this.setItems(event.target.value)
      this.$emit('update:input', event.target.value)
    },
    filterResults () {
      if (!this.lastWord || this.lastWord === '') {
        this.setItems(this.input)
        this.results = this.items
        return
      }

      this.results = this.items.filter((item) => {
        return item.toLowerCase().indexOf(this.lastWord.toLowerCase()) > -1
      })

      this.isOpen = true
    },
    setResult (result) {
      this.isOpen = false
      const input = this.input.replace(new RegExp(this.lastWord + '$'), `${result}`)
      this.$emit('update:input', input)
      this.$refs.input.focus()
      this.setItems(input)
    },
    onArrowDown () {
      if (this.arrowCounter >= this.results.length) {
        return
      }

      this.arrowCounter = this.arrowCounter + 1
    },
    onArrowUp () {
      if (this.arrowCounter <= 0) {
        return
      }

      this.arrowCounter = this.arrowCounter - 1
    },
    onEnter () {
      if (this.isOpen && this.results[this.arrowCounter]) {
        this.setResult(this.results[this.arrowCounter])
        return
      }

      this.$emit('enter', this.input)
    },
    handleClickOutside (event) {
      if (this.$el.contains(event.target)) {
        this.filterResults()
        return
      }

      if (event.clientX === 0 && event.clientY === 0) {
        return
      }

      this.isOpen = false
      this.arrowCounter = -1
    }
  }
}
</script>
<style lang="scss">
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #404553;
    max-height: 300px;
    overflow: hidden;
    width: 100%;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    .dashboard.is-dark & {
      background-color: #1f2128;
    }
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    cursor: pointer;
    padding-bottom: calc(.5em - 1px);
    padding-left: calc(.75em - 1px);
    padding-right: calc(.75em - 1px);
    padding-top: calc(.5em - 1px);
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #3273dc;
  }

</style>
