<template>
  <div style="margin-bottom: 1.25em;">
    <div class="columns is-multiline">
      <div class="column is-one-third">
        <div class="has-text-centered-mobile">
          <h3 class="subtitle">
            Events
          </h3>
          <h2 class="title">
            {{ indicesCount.toLocaleString() }}
          </h2>
        </div>
      </div>

      <div class="column">
        <div class="has-text-centered-mobile">
          <h3 class="subtitle">
            Size
          </h3>
          <h2 class="title">
            {{ formatSize(indicesSize) }}
          </h2>
        </div>
      </div>

      <div class="column is-narrow">
        <div class="has-text-right has-text-centered-mobile">
          <h3 class="subtitle">
            Indices
          </h3>
          <h2 class="title">
            {{ indices.length }}
          </h2>
        </div>
      </div>

      <div class="column is-one-third">
        <div class="has-text-right has-text-centered-mobile">
          <h3 class="subtitle">
            Status
          </h3>
          <h2 class="title">
            <a
              v-for="i in indices" :key="i.index"
              :title="health(i)" :class="{
                tag: true,
                'is-square': true,
                'is-warning': i.health === 'yellow',
                'is-success': i.health === 'green',
                'is-error': i.health === 'red'
              }" style="color: inherit"
              @click.prevent="external(`index:${i.index}`)"
            >{{ i.index }}</a>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    indices: {
      type: Array,
      required: true
    }
  },
  computed: {
    indicesCount () {
      if (!this.indices || !this.indices.length) {
        return 0
      }

      return this.indices.reduce((x, next) => {
        return x + parseInt(next.count)
      }, 0)
    },
    indicesSize () {
      if (!this.indices || !this.indices.length) {
        return 0
      }

      return this.indices.reduce((x, next) => {
        return x + this.parseSize(next.size)
      }, 0)
    }
  },
  methods: {
    health (index) {
      if (index.health === 'red') {
        return 'Error'
      }

      if (index.health === 'yellow') {
        return 'Normal - Single Instance'
      }

      return 'Normal'
    },
    external (query) {
      this.$emit('query', query)
    },
    formatSize (size) {
      if (!size) {
        return 0
      }

      var i = Math.floor(Math.log(size) / Math.log(1024))
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
    },
    parseSize (input) {
      var parsed = input.toString().match(/^([0-9.,]*)(?:\s*)?(.*)$/)
      var amount = parsed[1].replace(',', '.')
      var unit = parsed[2]

      const increments = [
        [['b', 'bit', 'bits'], 1 / 8],
        [['B', 'Byte', 'Bytes', 'bytes'], 1],
        [['Kb'], 128],
        [['k', 'K', 'kb', 'KB', 'KiB', 'Ki', 'ki'], 1024],
        [['Mb'], 131072],
        [['m', 'M', 'mb', 'MB', 'MiB', 'Mi', 'mi'], Math.pow(1024, 2)],
        [['Gb'], 1.342e+8],
        [['g', 'G', 'gb', 'GB', 'GiB', 'Gi', 'gi'], Math.pow(1024, 3)],
        [['Tb'], 1.374e+11],
        [['t', 'T', 'tb', 'TB', 'TiB', 'Ti', 'ti'], Math.pow(1024, 4)],
        [['Pb'], 1.407e+14],
        [['p', 'P', 'pb', 'PB', 'PiB', 'Pi', 'pi'], Math.pow(1024, 5)],
        [['Eb'], 1.441e+17],
        [['e', 'E', 'eb', 'EB', 'EiB', 'Ei', 'ei'], Math.pow(1024, 6)]
      ]

      for (var i = 0; i < increments.length; i++) {
        var _increment = increments[i]

        if (_increment[0].some(u => u === unit)) {
          return Math.round(amount * _increment[1])
        }
      }
    }
  }
}
</script>
