<template>
  <section class="section" style="position: relative;">
    <h2 class="title">
      {{ $t('route.search') }}
      <div class="subtitle">
        {{ $t('subtitle.search') }}
      </div>
    </h2>

    <div style="margin-bottom: 1.25em;">
      <div class="field has-addons">
        <div class="control is-expanded">
          <search-autocomplete
            ref="queryinput" :mappings="mappings" :indexes="allIndices"
            :input.sync="query" @enter="submit"
          />
        </div>
        <div class="control">
          <range-picker
            ref="rangepicker" :range.sync="dates" :lookup.sync="range"
            :disabled="loading"
          />
        </div>
        <div class="control">
          <button class="button is-primary" :disabled="loading" @mousedown="submit">
            <template v-if="loading">
              <img src="@/assets/loading.svg" style="height: 1em;" class="octicon"> <span>Loading</span>
            </template>
            <template v-else>
              <octicon :icon="search" /> <span>Query</span>
            </template>
          </button>
        </div>
      </div>
      <p v-if="result">
        {{ $t('search.result', { total: total.toLocaleString(), start: $d(dates[0], 'long'), end: $d(dates[1], 'long') }) }}
      </p>
    </div>
    <search-summary :indices="indices" @query="queryUpdate" />
    <box v-if="result">
      <tabs :show.sync="active" :theme="$_ui_theme_tabs" keep-alive>
        <tab title="Events" :icon="rss">
          <search-events
            :total="total" :dates="dates" :query="query"
            :mappings="mappings" :index="index" :index-query="indexQuery"
            :search-data="result"
            @query="queryUpdate"
          />
        </tab>
        <tab title="Visualization" :icon="paintcan">
          <search-visualization
            :total="total"
            :range="range" :dates="dates" :query="query"
            :vizs="vizs" :type.sync="type" :search-data="result"
          />
        </tab>
        <tab end ghost>
          <template v-slot:nav="data">
            <search-actions
              :class="data.class" :vizs="vizs"
              :range="range" :dates="dates" :query="query"
              :type="type" :range-text="$refs.rangepicker ? $refs.rangepicker.text : range"
            />
          </template>
        </tab>
      </tabs>
    </box>
    <search-latest @query="queryClick" />
  </section>
</template>
<script>
import { charts } from '@/utils'
import { search, paintcan, pin, alert, rss } from 'octicons-vue'
import RangePicker from '@/components/RangePicker'
import { Tabs, Tab } from '@hiendv/vue-tabs'
import SearchAutocomplete from '@/components/SearchAutocomplete'
import SearchLatest from '@/components/SearchLatest'
import SearchEvents from '@/components/SearchEvents'
import SearchVisualization from '@/components/SearchVisualization'
import SearchActions from '@/components/SearchActions'
import SearchSummary from '@/components/SearchSummary'

export default {
  components: {
    RangePicker,
    Tabs,
    Tab,
    SearchVisualization,
    SearchActions,
    SearchEvents,
    SearchSummary,
    SearchLatest,
    SearchAutocomplete
  },
  data () {
    return {
      active: 0,
      allIndices: [],
      indices: [],
      mappings: null,
      result: null,
      range: 'day0',
      dates: [],
      query: '',
      total: 0,
      loading: false,
      type: null
    }
  },
  computed: {
    index () {
      if (!this.query) {
        return ''
      }

      const matches = this.query.match(/index:(\S+)/m)
      if (!matches) {
        return ''
      }

      return matches[1]
    },
    indexQuery () {
      if (!this.query) {
        return ''
      }

      const matches = this.query.match(/^(index:(.*?))(\|(.*))?$/m)
      if (!matches) {
        return ''
      }

      return matches[1]
    },
    vizs () {
      if (!this.result || !(this.result.types instanceof Array)) {
        return []
      }

      return charts().map(viz => {
        if (this.result.types.indexOf(viz.value) < 0) {
          viz.disabled = true
        }

        return viz
      })
    },
    search () {
      return search
    },
    pin () {
      return pin
    },
    paintcan () {
      return paintcan
    },
    alert () {
      return alert
    },
    rss () {
      return rss
    }
  },
  watch: {
    result () {
      if (this.result && this.result.types) {
        this.active = 1
        return
      }

      this.active = 0
    },
    $route: 'loadFromRoute'
  },
  created () {
    this.init()
  },
  mounted () {
    this.loadFromRoute()
  },
  methods: {
    loadFromRoute () {
      if (this.$route && this.$route.params && this.$route.params.widget) {
        const widget = this.$route.params.widget
        this.query = widget.query
        this.dates = widget.dates.map(d => new Date(d))
        this.range = widget.range

        this.$nextTick(() => {
          this.submit()
        })
      }

      if (this.$route && this.$route.query && this.$route.query.q) {
        this.query = this.$route.query.q
        this.range = this.$route.query.range

        this.$nextTick(() => {
          this.submit()
        })
      }
    },
    queryClick (val) {
      this.query = val.query
      this.$refs.queryinput.$el.querySelector('.input').focus()
    },
    queryUpdate (val) {
      this.query = val
      this.$refs.queryinput.$el.querySelector('.input').focus()
      this.submit()
    },
    reloadRangePicker () {
      if (!this.$refs.rangepicker) {
        return
      }

      this.$refs.rangepicker.reloadSuggestions()
    },
    submit () {
      this.reloadRangePicker()
      if (!this.query) {
        return this.$store.dispatch('NOTIFY', {
          type: 'warning',
          error: {
            message: 'Please enter the query'
          }
        })
      }

      if (!this.dates || !this.dates.length) {
        return this.$store.dispatch('NOTIFY', {
          type: 'warning',
          error: {
            message: 'Please select a range'
          }
        })
      }

      if (!this.$route || !this.$route.query || (this.$route.query.q !== this.query || this.$route.query.range !== this.range)) {
        this.$router.push({
          name: 'search',
          query: {
            q: this.query,
            range: this.range
          }
        })
        return
      }

      this.loading = true
      this.count()
        .then(total => {
          const t = parseInt(total)
          if (Number.isNaN(t)) {
            return
          }

          this.total = t
        })
        .catch(() => {
          this.loading = false
        })

      this.result = null
      this.do()
        .then(normalized => {
          if (!normalized) {
            return
          }

          this.result = normalized

          if (this.result.chart_type === 'delete') {
            return this.$store.dispatch('NOTIFY', {
              type: 'success',
              text: 'Deleted ' + this.result.deleted + ' records'
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    do () {
      if (!this.query) {
        return Promise.resolve()
      }

      if (!this.dates || this.dates.length !== 2) {
        return Promise.resolve()
      }

      return this.$_search({
        query: this.query,
        start: this.dates[0],
        end: this.dates[1],
        history: true
      })
    },
    count () {
      if (!this.indexQuery) {
        return Promise.resolve()
      }

      if (!this.dates || this.dates.length !== 2) {
        return Promise.resolve()
      }

      return this.$_search({
        query: `${this.indexQuery} | count by _index`,
        start: this.dates[0],
        end: this.dates[1],
        pure: true
      }).then(data => {
        if (!data) {
          return
        }

        return parseInt(data.total)
      })
    },
    init () {
      Promise.all([
        this.$http.get('/api/v1/search/indices'),
        this.$http.get('/api/v1/search/mapping')
      ]).then(rsps => {
        this.initIndices(rsps[0])
        this.initMappings(rsps[1])
      })
    },
    initIndices (body) {
      if (!body || !body.data) {
        return
      }

      const payload = body.data.data
      if (!payload || !payload.indices || !payload.indices.length) {
        return
      }

      this.indices = payload.indices.map(val => {
        return {
          index: val.index,
          count: val['docs.count'],
          deleted: val['docs.deleted'],
          size: val['pri.store.size'],
          health: val.health
        }
      })
    },
    initMappings (body) {
      if (!body || !body.data) {
        return
      }

      const payload = body.data.data
      if (!payload) {
        return
      }

      const mappings = {}
      const allFields = (index) => {
        if (!index.properties) {
          return []
        }

        let fields = []

        Object.keys(index.properties).forEach(field => {
          const extra = allFields(index.properties[field])
          if (extra.length) {
            fields = fields.concat(extra.map(f => `${field}.${f}`))
            return
          }

          fields = fields.concat(field)
        })

        return fields
      }

      Object.keys(payload).forEach(name => {
        this.allIndices = this.allIndices.concat('index:' + name)
        mappings[name] = allFields(payload[name].mappings)
      })

      this.mappings = mappings
    }
  }
}
</script>
<style lang="scss">
[disabled].input {
  background-color: #e0e1ea;
}
</style>
