var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"1.25em"}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-one-third"},[_c('div',{staticClass:"has-text-centered-mobile"},[_c('h3',{staticClass:"subtitle"},[_vm._v("\n          Events\n        ")]),_vm._v(" "),_c('h2',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_vm.indicesCount.toLocaleString())+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"column"},[_c('div',{staticClass:"has-text-centered-mobile"},[_c('h3',{staticClass:"subtitle"},[_vm._v("\n          Size\n        ")]),_vm._v(" "),_c('h2',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_vm.formatSize(_vm.indicesSize))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"column is-narrow"},[_c('div',{staticClass:"has-text-right has-text-centered-mobile"},[_c('h3',{staticClass:"subtitle"},[_vm._v("\n          Indices\n        ")]),_vm._v(" "),_c('h2',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_vm.indices.length)+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"column is-one-third"},[_c('div',{staticClass:"has-text-right has-text-centered-mobile"},[_c('h3',{staticClass:"subtitle"},[_vm._v("\n          Status\n        ")]),_vm._v(" "),_c('h2',{staticClass:"title"},_vm._l((_vm.indices),function(i){return _c('a',{key:i.index,class:{
              tag: true,
              'is-square': true,
              'is-warning': i.health === 'yellow',
              'is-success': i.health === 'green',
              'is-error': i.health === 'red'
            },staticStyle:{"color":"inherit"},attrs:{"title":_vm.health(i)},on:{"click":function($event){$event.preventDefault();return _vm.external(`index:${i.index}`)}}},[_vm._v(_vm._s(i.index))])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }