<template>
  <div>
    <div class="columns">
      <div v-for="t in vizs" :key="t.value" class="column">
        <button :class="['button is-fullwidth', t.value === typeActual.value ? 'is-primary' : 'is-info']" :disabled="t.disabled" @click.prevent="selectType(t)">
          {{ t.name }}
        </button>
      </div>
    </div>

    <template v-if="available">
      <visualization :data="vizData" chart-height="300px">
        <template v-if="resultEvents && resultEvents.length && !table" slot="below-chart">
          <hr>
          <data-table :items="resultEvents" :theme="$_ui_theme_tables">
            <data-column
              v-for="(field, index) in resultFields" :key="field + index" :field="field"
              :label="resultFieldsName[index]"
            />
          </data-table>
        </template>
      </visualization>
    </template>
    <p v-else class="has-text-centered">
      {{ $t('search.errorNoCharts') }}
    </p>
  </div>
</template>
<script>
import Visualization from '@/components/Visualization'
import { DataTable, DataColumn } from '@cyradar/ui'

export default {
  components: { DataTable, DataColumn, Visualization },
  props: {
    dates: {
      type: Array,
      required: true
    },
    searchData: {
      type: Object,
      required: true
    },
    vizs: {
      type: Array,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    type: {
      type: Object,
      default: null
    },
    total: {
      type: Number,
      default: 0
    },
    range: {
      type: String,
      required: true
    }
  },
  computed: {
    typeActual () {
      if (this.type) {
        return this.type
      }

      const viz = this.vizs.find(viz => !viz.disabled)
      if (viz) {
        return viz
      }

      return {}
    },
    available () {
      return !!this.typeActual.value
    },
    resultChartType () {
      return this.searchData.chartType instanceof Array ? this.searchData.chartType : 'events'
    },
    resultEvents () {
      return this.searchData.events instanceof Array ? this.searchData.events : []
    },
    resultFields () {
      return this.searchData.fields instanceof Array ? this.searchData.fields : []
    },
    resultFieldsName () {
      return this.searchData.fieldsName instanceof Array ? this.searchData.fieldsName : []
    },
    resultGeo () {
      return this.searchData.geo instanceof Array ? this.searchData.geo : []
    },
    table () {
      return this.typeActual.value === 'table'
    },
    sum () {
      return this.typeActual.value === 'sum'
    },
    map () {
      return this.typeActual.value === 'map'
    },
    bpmap () {
      return this.typeActual.value === 'bpmap'
    },
    vizData () {
      if (this.dates.length !== 2) {
        return {}
      }

      if (this.table) {
        if (this.searchData.chartType === 'count_statistic') {
          return {
            chartType: this.searchData.chartType,
            type: this.typeActual.value,
            events: this.resultEvents,
            columns: this.resultFieldsName,
            query: this.query,
            total: this.resultEvents.length,
            start: this.dates[0].getTime(),
            end: this.dates[1].getTime(),
            range: this.range
          }
        }

        return {
          chartType: this.searchData.chartType,
          type: this.typeActual.value,
          events: this.resultEvents,
          columns: this.resultFields,
          query: this.query,
          total: this.total,
          start: this.dates[0].getTime(),
          end: this.dates[1].getTime(),
          range: this.range
        }
      }

      if (this.sum) {
        return {
          type: this.typeActual.value,
          total: this.total,
          start: this.dates[0].getTime(),
          end: this.dates[1].getTime(),
          range: this.range
        }
      }

      if (this.map) {
        return {
          type: this.typeActual.value,
          data: this.resultGeo,
          originalData: this.searchData.data,
          currentLocation: this.searchData.currentLocation,
          field: this.searchData.geoField,
          rootField: this.searchData.geoRoot,
          start: this.dates[0].getTime(),
          end: this.dates[1].getTime(),
          range: this.range
        }
      }

      if (this.bpmap) {
        return {
          type: this.typeActual.value,
          data: this.searchData.data
        }
      }

      return {
        type: this.typeActual.value,
        events: this.resultEvents,
        interval: this.searchData.interval,
        start: this.dates[0].getTime(),
        end: this.dates[1].getTime(),
        range: this.range
      }
    }
  },
  methods: {
    selectType (t) {
      this.$emit('update:type', t)
    }
  }
}
</script>
