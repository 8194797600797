<template>
  <box>
    <div slot="header">
      Latest Queries
    </div>

    <table class="table is-striped is-fullwidth is-narrow">
      <thead>
        <tr>
          <th width="20%">
            Time
          </th>
          <th>Query</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="call in history" :key="call.id">
          <td>{{ format(new Date(call.time_request), 'dd/MM/yyyy HH:mm:ss') }}</td>
          <td><a href="#" @click.prevent="$emit('query', call)">{{ call.query }}</a></td>
        </tr>
      </tbody>
    </table>
  </box>
</template>
<script>
import format from 'date-fns/format'
export default {
  data () {
    return {
      history: []
    }
  },
  mounted () {
    this.loadHistory()
  },
  methods: {
    format,
    loadHistory () {
      return this.$http.get('/api/v1/search/history').then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }

        this.history = body.data.data
      })
    }
  }
}
</script>
