<template>
  <div>
    <h5 class="search-mappings-title">
      Fields
    </h5>

    <nav class="search-mappings">
      <li v-for="(mapping, name) in mappings" :key="name" class="search-mapping">
        <span v-if="mappingOpen[name]">{{ name }}</span>
        <ul v-if="mappingOpen[name]" class="search-mapping-fields">
          <li v-for="field in mapping" :key="field">
            <popover placement="right" width="400px" @shown="shown(index, field)">
              <a
                slot="trigger" href="#" class="is-size-7"
                @click.prevent
              >
                {{ field }}
              </a>
              <template v-slot:default="slotProps">
                <div class="has-text-left is-size-7">
                  <h4 class="title is-5">
                    {{ field }}
                  </h4>
                  <p class="subtitle is-6">
                    <button class="button is-small" @click.prevent="external(`index:${name} | top by ${field}`)">
                      Top Values
                    </button>
                    <button class="button is-small" @click.prevent="external(`index:${name} | timechart by ${field}`)">
                      Top Values By Time
                    </button>
                    <button class="button is-small" @click.prevent="external(`index:${name} ${field}:*`)">
                      Events with this field
                    </button>
                  </p>
                  <data-table
                    v-if="slotProps.active" :items="items" disable-filtering
                    :theme="$_ui_theme_tables" :pagination="[]"
                  >
                    <data-column label="Value" :sortable="false">
                      <template slot-scope="props">
                        <div class="has-text-left">
                          <a class="is-text" @click.prevent="external(`index:${name} ${field}:${props.item.key}`)">
                            <span>{{ props.item.key }}</span>
                          </a>
                        </div>
                      </template>
                    </data-column>
                    <data-column field="value" label="Count" :sortable="false" />
                  </data-table>
                </div>
              </template>
            </popover>
          </li>
        </ul>
      </li>
    </nav>
  </div>
</template>
<script>
import { DataTable, DataColumn } from '@cyradar/ui'

export default {
  components: { DataTable, DataColumn },
  props: {
    mappings: {
      type: Object,
      required: true
    },
    dates: {
      type: Array,
      required: true
    },
    index: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      mappingOpen: {},
      loaded: {
        index: '',
        field: ''
      }
    }
  },
  watch: {
    index: {
      immediate: true,
      handler  (val) {
        if (!val) {
          return
        }

        if (this.mappingOpen[val]) {
          return
        }

        this.toggleMapping(val)
      }
    }
  },
  methods: {
    shown (index, field) {
      this.loaded = {
        index,
        field
      }
    },
    external (query) {
      this.$emit('query', query)
    },
    items () {
      if (!this.loaded || !this.loaded.index || !this.loaded.field) {
        return {
          total: 0,
          items: []
        }
      }

      if (!this.dates || this.dates.length !== 2) {
        return {
          total: 0,
          items: []
        }
      }

      return this.$_search({
        query: `index:${this.loaded.index} | top by ${this.loaded.field}`,
        start: this.dates[0],
        end: this.dates[1]
      }).then(normalized => {
        if (!normalized) {
          return {
            total: 0,
            items: []
          }
        }

        return {
          total: normalized.events.length,
          items: normalized.events
        }
      })
    },
    toggleMapping (name) {
      if (!this.mappingOpen) {
        this.mappingOpen = {
          [name]: true
        }
        return
      }

      this.$set(this.mappingOpen, name, !this.mappingOpen[name])
    }
  }
}
</script>
<style lang="scss">
$x: #1f2128;

.search-mappings-title {
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: .5em;
  padding-bottom: .5em;

  .dashboard.is-dark & {
    border-color: lighten($x, 15%);
  }
}

.search-mappings {
  list-style: none;
}

.search-mapping {
  & > div {
    position: relative;
  }
}

.search-mapping-toggle {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: #b5b5b5;
  display: flex;
  justify-content: flex-end;
}

.search-mapping-fields {
  padding-left: 1em;
  max-height: 30rem;
  overflow-y: scroll;
}
</style>
